import React from "react";
import { FormattedMessage } from 'react-intl'

export default function ErrorFallback({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div className="container narrow">
      <div className="notification is-danger is-light has-text-weight-bold has-text-centered">
        {children || <FormattedMessage id="generalError" />}
      </div>
    </div>
  );
}
