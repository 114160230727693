import React from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export default function ErrorBoundary({ children, fallback }: Props) {
  return (
    <SentryErrorBoundary fallback={fallback as React.ReactElement} showDialog>
      {children}
    </SentryErrorBoundary>
  );
}
