import React from "react";
import Layout from "../components/layout";

export default function Index() {
  return (
    <Layout>
      <div className="container sk-container">
        <div className="is-flex is-justify-content-center">
          <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
