/// <reference types="vite/types/import-meta" />
import * as React from "react";
import FourOhFour from "./pages/404";
import { Route, Routes } from "react-router";
import { withSentryReactRouterV6Routing } from "@sentry/react";

const routeModules = import.meta.glob("./pages/**/[a-z]+[a-z0-9]*.[tj]sx");

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const loadedModules: Set<string> = new Set();

const ROUTES = Object.entries(routeModules).map(([key, load]) => {
  const path = key
    .replace(/\.[tj]sx$/, "")
    .replace(/index$/, "")
    .replace("./pages", "");

  if (import.meta.env.SSR) {
    const moduleName = key.replace(/^\.\//, "");
    return [
      path,
      React.lazy(() => {
        loadedModules.add(moduleName);
        return load() as any;
      }),
    ] as const;
  }

  return [path, React.lazy(load as any)] as const;
});

const routes = ROUTES.map(([path, Component]) => (
  <Route key={path} path={path} element={<Component />} />
));

export default function AppRoutes() {
  return (
    <SentryRoutes>
      {routes}
      <Route path="*" element={<FourOhFour />} />
    </SentryRoutes>
  );
}
