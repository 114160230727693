import React from "react";

import "../styles/index.scss";

const Layout = (props: { children: React.ReactNode }) => (
  <div id="layout">
    <div id="layout-header">
      <svg
        id="logo"
        width="100%"
        height="100%"
        viewBox="0 0 72 72"
        version="1.1"
      >
        <g id="group">
          <g id="Layer_2">
            <path
              id="Shape"
              d="M71.5 71.5 C35.369 71.872 28.522 71.573 26 70.644 24.075 69.935 20.697 68.422 18.493 67.281 16.289 66.141 12.207 62.797 9.42 59.851 6.573 56.841 3.51 52.306 2.427 49.497 1.03 45.874 0.505 42.027 0.518 35.5 0.534 27.773 0.919 25.717 3.241 20.962 4.728 17.916 8.077 13.291 10.684 10.684 13.291 8.078 17.916 4.728 20.962 3.241 25.716 0.919 27.773 0.534 35.5 0.519 42.018 0.505 45.878 1.031 49.5 2.424 52.408 3.543 56.82 6.559 60.046 9.632 63.483 12.906 66.715 17.264 68.546 21.092 71.5 27.269 71.5 27.269 71.5 49.385 L71.5 71.5 Z M15.833 53 C16.211 53 24.841 44.652 35.01 34.449 53.5 15.898 53.5 15.898 50.736 13.485 49.216 12.158 46.066 10.493 43.736 9.786 41.406 9.079 37.475 8.5 35 8.5 32.525 8.5 28.558 9.144 26.184 9.932 23.811 10.719 19.927 13.073 17.554 15.161 15.181 17.25 12.224 21.106 10.983 23.73 9.578 26.702 8.685 30.573 8.614 34 8.551 37.025 9.062 41.352 9.75 43.615 10.437 45.878 11.932 48.915 13.072 50.365 14.212 51.814 15.454 53 15.833 53 Z"
              fill="#ffffff"
              fillOpacity="1"
              stroke="none"
            />
            <path
              id="Shape-1"
              d="M15.658 52.994 C15.021 52.99 13.453 51.449 12.173 49.57 10.894 47.69 9.363 43.306 8.771 39.827 7.875 34.555 7.979 32.529 9.398 27.673 10.557 23.708 12.428 20.407 15.255 17.339 17.54 14.86 21.052 11.994 23.06 10.97 25.067 9.945 29.013 8.802 31.829 8.429 34.847 8.028 38.878 8.236 41.653 8.934 44.241 9.586 47.628 11.023 49.179 12.128 50.73 13.233 52 14.977 52 16.005 52 17.093 44.655 25.207 34.408 35.437 24.733 45.097 16.295 52.997 15.658 52.994 Z"
              fill="#90cbfa"
              fillOpacity="1"
              stroke="none"
            />
          </g>
        </g>
      </svg>
      <div id="logo-text">Cirrus</div>
    </div>
    <div id="layout-content">
      {props.children}
    </div>
  </div>
);

export default Layout;
