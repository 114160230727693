import React from "react";
import Layout from "../components/layout";
import Fallback from "../components/fallback";

export default function FourOhFour() {
  return (
    <Layout>
      <Fallback>
        We are unable to find what you were looking for.
      </Fallback>
    </Layout>
  );
}
