import React from "react";
import { Intl } from "./components/intl";
import { Title } from "react-head";
import Routes from "./Routes";
import Index from "./pages/index";
import ErrorBoundary from "./components/error";
import Layout from "./components/layout";
import ErrorFallback from "./components/fallback";

export default function App() {
  return (
    <ErrorBoundary fallback={<Fallback />}>
      <React.Suspense fallback={<Index />}>
        <Intl>
          <Title>Cirrus SSO</Title>
          <Routes />
        </Intl>
      </React.Suspense>
    </ErrorBoundary>
  );
}

function Fallback() {
  return (
    <Layout>
      <ErrorFallback>
        Something went wrong. Please reload the page.
      </ErrorFallback>
    </Layout>
  );
}
