import React from "react";
import messages from "../../lang/english.json";

import { IntlProvider } from "react-intl";

export default function EnIntlProvider(props: React.PropsWithChildren<{}>) {
  return (
    <IntlProvider locale="en-US" messages={messages}>
      {props.children}
    </IntlProvider>
  );
}
