import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { config as fontAwesomeConfig } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import App from "./App";
import { HeadProvider } from "react-head";
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  matchRoutes,
  createRoutesFromChildren,
} from "react-router-dom";

fontAwesomeConfig.autoAddCss = false;

if (typeof document !== "undefined") {
  const target = document.getElementById("root");

  if ((window as any).co_sentry) {
    Sentry.init({
      ...(window as any).co_sentry,
      integrations: [
        Sentry.replayIntegration(),
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
    });
  }

  const app = (
    <React.StrictMode>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <HeadProvider>
          <App />
        </HeadProvider>
      </BrowserRouter>
    </React.StrictMode>
  );

  if (target?.hasChildNodes()) {
    ReactDOM.hydrateRoot(target, app);
  } else {
    ReactDOM.createRoot(target!).render(app);
  }
}
