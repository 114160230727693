import React from "react";

import Default from "./en";
const Swedish = React.lazy(() => import("./sv"));
const English = React.lazy(() => import("./en"));

function useNavgigatorLanguage() {
  if (typeof document === "undefined") return "";

  const [lang, setLang] = React.useState(navigator.language);

  React.useEffect(() => {
    const listener = function () {
      React.startTransition(() => setLang(navigator.language));
    };
    window.addEventListener("languagechange", listener);
    return () => {
      window.removeEventListener("languagechange", listener);
    };
  });

  return lang;
}

function useIntlProvider(language: string) {
  const isSv = /^sv\b/i;
  const isEn = /^en\b/i;

  if (isSv.test(language)) return Swedish;
  if (isEn.test(language)) return English;
  return Default;
}

export function Intl(props: { children?: React.ReactNode }) {
  const language = useNavgigatorLanguage();
  const Provider = useIntlProvider(language);

  return <Provider>{props.children}</Provider>;
}
